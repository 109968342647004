import { domainInfoApi } from '@/api/normal';
import { buildUrlParam } from '@/hooks/BuildUrlParam';
import { generateManifestProtocolHandler } from '@/utils/pwa/manifest';
import { getWebDomain } from '@/utils/app';
import { getUrlParams } from '@/hooks/HttpCompletion';
import { BROWSER } from '@/enums/device';
import type {Router}  from 'vue-router';

export default async (router: Router) => {
  const systemStore = useSystemStore();
  const { browser } = toRefs(systemStore)
  let isSamsungInternet = browser.value !== BROWSER.SAMSUNG_INTERNET   // 是否是三星浏览器
  let appInfo = window.sessionStorage.getItem('appInfo')
  let domain = window.sessionStorage.getItem('domain')
  let host = getWebDomain()
  if (host.includes('localhost') || host.includes('5173')) {
    host = 'bc.qu6.xyz'
  }
  if (!appInfo || !domain || domain !== host) {

    const { configList, info
    } = await domainInfoApi(host)
    const { appIcon, siteName } = configList
    const { tenantId, language } = info
    window.sessionStorage.setItem('domain', host)
    window.sessionStorage.setItem("appInfo", JSON.stringify({ tenantId, appIcon, siteName,language }))
    // 三星浏览用js更新manifest.json无效，所以刷新页面
    if (isSamsungInternet) {
      location.reload()
    }
    return
  }

  let { tenantId, appIcon, siteName } = JSON.parse(appInfo)
  let token = window.sessionStorage.getItem('token')
  let accountInfo = window.sessionStorage.getItem('accountInfo')
  let acc = ''	// 获取账号
  let pass = '' // 获取密码
  if (accountInfo) {
    let result = JSON.parse(accountInfo)
    acc = result.acc
    pass = result.pwd
  }
  let urlParams = getUrlParams(window.location.href) || {}



  const params = buildUrlParam({
    // 构建url参数
    ...urlParams,
    token,
    acc,
    pass,
    sd: 2,

  });

  // 协议处理程序
  const start_url = `${location.origin}${location.pathname === '/download' ?  '/launch' : location.pathname}${params}`; // PWA启动url

  const protocolName = siteName || tenantId;
  const protocol_handlers = [generateManifestProtocolHandler(protocolName, start_url)];
  console.log('333start_url====', start_url, 'location.origin====', location.origin);
  const manifest = {
    theme_color: "black",
    background_color: "black",
    display: "standalone",
    orientation: "portrait",
    prefer_related_applications: false,
    related_applications: [],
    id: `${location.origin}/${tenantId}`,
    name: `${siteName}`,
    scope: `${location.origin}/`,
    short_name: `${siteName}`,
    start_url,
    icons: [
      {
        src: `${appIcon}`,
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: `${appIcon}`,
        sizes: "512x512",
        type: "image/png",
      },
    ],
    protocol_handlers,
  }

  window.webAppManifestSettings = manifest

console.log('manifest',manifest)
  // 将 manifest 对象转换为 JSON 字符串
  const manifestJson = JSON.stringify(manifest, null, 2);

  // 将 manifestData 转换为 Blob 并生成 URL
  //  const blob = new Blob([manifestJson], { type: 'application/json' });
  //  const manifestURL = URL.createObjectURL(blob);


  // 更新页面中的 manifest link
  let link = document.querySelector('link[rel="manifest"]');

  if (!link) {
    link = document.createElement("link");
    link.rel = "manifest";
    document.head.appendChild(link);
  }
  link.href = "data:text/json;charset=utf-8," + encodeURIComponent(manifestJson)
 // 三星浏览pwa 带参数过去必须在url有参数
  if (isSamsungInternet && !(urlParams?.sd === 2 && urlParams?.token === token && urlParams?.acc === acc && urlParams?.pass === pass)) {
    router.replace({ path: location.pathname, query: { ...urlParams, sd: 2 } })
    return

  }

}

