import { useAppStore } from "@/store/app";
import { useI18n } from "@/hooks/useI18n";
import { useUserStore } from "@/store/user";
import { showLogin } from "@/hooks/ShowLogin";
import { showPopup } from "@/hooks/ShowPopup";
import { toastWhiteList } from "./toastWhiteList";
import { PopupType } from "@/components/Popup/data";
import { ERROR_CODE } from "@/enums/types/errorCode";
import { observable } from "@trpc/server/observable";
import { showMaintenance } from "@/hooks/Maintenance";
import type { AppRouter } from "../../../nuxt/server/logic/trpc/frontend/routers";
import type { TRPCLink } from "@trpc/client";
import showToast from "@/utils/toast";
import router from "@/router";

const { t } = useI18n();

// 联系客服
function contactCustomService() {
  router.push("/notification");
}

// 显示错误信息弹窗
function showErrorPopup(clearToken: boolean = false) {
  useUserStore().isException = true;
  const msg = clearToken ? t("popup.tips10") : t("popup.tips09");
  showPopup({
    type: PopupType.EXCEPTION,
    msg,
    showRightBtn: true,
    leftBtnText: t("viewsUser.contactCustService"),
    rightBtnText: t("main.confirm"),
    leftBtnCallback: contactCustomService,
    rightBtnCallback: clearToken ? showLogin : undefined,
  });
}

export const errorHandleLink: TRPCLink<AppRouter> = () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({ next, op }) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable((observer) => {
      const subscribe = () => {
        const unsubscribe = next(op).subscribe({
          next(value) {
            observer.next(value);
          },
          error(err) {
            // @ts-expect-error 限制请求
            if (err.meta?.response?.status === 429) {
              showToast(t("toast.error429", { ip: '' }));
              observer.error(err);
							return;
            }
            // @ts-expect-error 未授权
            if (err.meta?.response?.status === 412) {
              const message = JSON.parse(err.message);
              // 600: 站点维护中
              if (message.code === 600) {
                showMaintenance(message.message);
                return;
              }
            }
            // @ts-expect-error
            if (err.meta?.response?.status === 401) {
              try {
                const message = JSON.parse(err.message);
                if (message.code === "UNAUTHORIZED") {
                  useAppStore().removeToken(); // 移除token
                  useUserStore().removeUser(); // 移除个人信息
                  showErrorPopup(true);
                }
              } catch (e) {
                console.error(err.message);
              }
              observer.error(err);
              return;
            }
            // @ts-expect-error 禁止
            if (err.meta?.response?.status === 403) {
              try {
                const message = JSON.parse(err.message);
                if (message.code === "USERS:LOGIN" || message.code === ERROR_CODE.LOGIN_BLACKLIST) {
                  showErrorPopup();
                } else {
                  showToast(message.message);
                }
              } catch (e) {
                showToast(err.message ?? t("tip.serverBusy"));
              }
              observer.error(err);
              return;
            }
            try {
              const message = JSON.parse(err.message);
              if (message.code === ERROR_CODE.LOGIN_BLACKLIST) {
                showErrorPopup();
                return observer.error(err);
              } else if (message[0].code === "invalid_type") {
                //  如果是参数类型错误直接抛出错误(不提示错误信息)
                return observer.error(err);
              }
            } catch (e) {
              console.error(err.message);
            }
            if (!toastWhiteList.includes(op.path)) {
              // 判断是否在提示白名单内
              showToast(err.message); // 弹窗提示错误信息
            }
            observer.error(err);
          },
          complete() {
            observer.complete();
          },
        });
        return unsubscribe;
      };
      return subscribe();
    });
  };
};
