import { useTenantStore } from "@/store/tenant";

// 主题皮肤配置
const themeConfig = {
  "H5Dark:DarkGreen": { skin: "first", theme: "green-dark", color: '#22262E' },
  "Layout2:DarkGreen": { skin: "first", theme: "green-dark", color: '#22262E' },
  "H5Dark:GoldenYellow": { skin: "first", theme: "yellow-dark", color: '#262624' },
  "Layout2:GoldenYellow": { skin: "first", theme: "yellow-dark", color: '#262624' },
  "H5Dark:BluePurple": { skin: "first", theme: "purple-light", color: '#6526db' },
  "Layout2:BluePurple": { skin: "first", theme: "purple-light", color: '#6526db' },
  "Layout1:Green": { skin: "default", theme: "green-default", color: '#2B4F14' },
  "Layout3:AmberPurple": { skin: "second", theme: "amber-purple", color: '#262346' },
  "Layout1:GreenV01": { skin: "default", theme: "green-v01", color: '#2B4F14' },
  "Layout1:GreenV02": { skin: "default", theme: "green-v02", color: '#2B4F14' },
} satisfies Record<string, { skin: string; theme: string, color: string }>;

/**
 * @description 获取当前配置主题
 */
export const getTheme = () => {
  const tenantStore = useTenantStore();
  let skin = import.meta.env.VITE_SKIN;
  let theme = import.meta.env.VITE_THEME;
  let color = '#090F1F';
  if (!skin || !theme) {
    const skinTwoType = tenantStore.tenantInfo?.skinTwoType as keyof typeof themeConfig;
    const defaultConfig = { skin: "default", theme: "blue-default", color };
    const config = themeConfig[skinTwoType] || defaultConfig;
    skin = config.skin;
    theme = config.theme;
    color = config.color;
  }
  return {
    skin,
    theme,
    color,
  };
};
