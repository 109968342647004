
/**
 * @description 设备类型
 */
export enum DeviceType {
  /** 未知 */
  Unknown = 0,
  /** PC */
  PC = 1,
  /** 安卓 */
  Android = 2,
  /** IOS */
  IOS = 3,
}
// 体育游戏平台
export const sportsType = ['M8SPORTS', 'basha', 'Betby']