// 数据校验

import { isJSON } from "validator"

// 当前值是否为JSON字符串
export function isJSONStr(value: any): boolean {
	if (typeof value === 'string') {
		return isJSON(value)
	}
	return false
}
