// 公用的弹窗组件
import { modalController } from '@ionic/vue'
import { PopupParams, PopupType } from '@/components/Popup/data';
import { defineAsyncComponent } from 'vue';
import { getTheme } from '@/theme/hooks'
import _ from 'lodash-es';

// 弹窗队列
let popupQueue: { [key: string]: PopupParams[]} = {
  [PopupType.EXCEPTION]: [],
  [PopupType.TIPS]: [],
  [PopupType.FLOW]: [],
  [PopupType.BONUS]: [],
  [PopupType.TRIAL]: [],
};

// 弹窗是否显示中
let isPopupShow = false;

/**
 * @description 寻找下一个弹窗
 */
function findNextPopup(): PopupParams | null{
  for (const key in popupQueue) {
    if (popupQueue[key].length > 0) {
      return popupQueue[key][0] ?? null;
    }
  }
  return null;
}

/**
 * @description 清除弹窗队列
 */
function clearPopupQueue() {
  for (const key in popupQueue) {
    if(key !== PopupType.EXCEPTION.toString()) {
      popupQueue[key] = [];
    }
  }
}

/**
 * @description 弹窗队列去重
 */
function uniquePopupQueue() {
  for (const key in popupQueue) {
    popupQueue[key] = _.uniqWith(popupQueue[key], _.isEqual);
  }
}

/**
 * @description 显示下一个弹窗
 */
async function showNextPopup() {
  const options = findNextPopup();
  if (options) {
    const { type, component, id } = options;
    const { skin } = getTheme()

    // 检测是否拦截当前弹窗
    if (typeof options.checkIntercept === 'function' && options.checkIntercept()) {
      popupQueue[type!].shift();
      showNextPopup();
      return;
    }

    const popup = await modalController.create({
      id: id ?? 'popup',
      component: component ?? defineAsyncComponent(() => import(`@/components/Popup/${skin}/index.vue`)),
      componentProps: options,
      backdropDismiss: false,
      animated: true,
    });
    popup.present();
  
    // 弹窗关闭
    await popup.onWillDismiss();
    uniquePopupQueue();
    await popup.onDidDismiss();
    popupQueue[type!].shift();
    isPopupShow = false;
    showNextPopup();
  }
}

/**
 * @description 显示弹窗
 */
export async function showPopup(options: PopupParams) {
  const defaultOptions = {
    allowRepeat: false,
    type: PopupType.TIPS,
    component: null,
    msg: '',
  };
  const finalOptions = { ...defaultOptions, ...options };
  const { allowRepeat, type } = finalOptions;

  if (!allowRepeat) {
    const isExist = popupQueue[type].some((item) => _.isEqual(item, finalOptions));
    if (isExist) return;
  }

  // 账号异常弹窗直接清除队列
  if (type === PopupType.EXCEPTION) {
    clearPopupQueue()
    await modalController.dismiss().catch((error) => {
      console.warn('No Model=', error);
    });
  }
  popupQueue[type].push(finalOptions);

  if (!isPopupShow) {
    isPopupShow = true;
    showNextPopup();
  }
}
