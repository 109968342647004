

export function getApiUrl() {
  if (import.meta.env.VITE_USE_API_PROXY) {
    return ''
  }
  return import.meta.env.VITE_TRPC_URL || 'https://api.g6b.xyz'
}


export function getEnvHeaders() {
  const headers: Record<string, string> = {};

  if (import.meta.env.VITE_ENV === 'dev') {
    headers['X-Dev'] = 'true';
  }

  if (import.meta.env.VITE_IS_GRAY) {
    headers['X-Gray'] = 'true';
  }

  return headers;
}
