import { createI18n } from 'vue-i18n';
import en_US from './locales/en_US.json';
import zh_CN from './locales/zh_CN.json';
import pt_BR from './locales/pt_BR.json';
import id_ID from './locales/id_ID.json';
import hi_IN from './locales/hi_IN.json';

export const sysLocale = 'pt-BR';

const i18n = createI18n({
  legacy: false,      // 设置为 false 来启用 composition API 模式
  locale: sysLocale,  // 默认显示的语言
  messages: {
    "en-PH": en_US,
    "en-US": en_US,
    "zh-CN": zh_CN,
    "pt-BR": pt_BR,
    "id-ID": id_ID,
    "hi-IN": hi_IN,
  }
})

export const getCurrency = (locale: string) => locale.split('-')[1];

export const getLanguages = () => Object.keys(i18n.global.messages);

export const t = i18n.global.t;

export const locale = i18n.global.locale;

export default i18n;
